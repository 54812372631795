import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Feel free to reach out to us with your questions and feedback.</p>
    </div>
  );
}

export default Contact;
