import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './AboutUs.css'; // Make sure to import your custom CSS file correctly

const AboutUs = () => {
  // Array of team member info
  const teamMembers = [
    { src: "/nizam.jpg", name: "A", title: "Founding Engineer" },
    { src: "/nizam.jpg", name: "B", title: "Operations Head" },
    { src: "/nizam.jpg", name: "V", title: "Marketing Head" },
    { src: "/nizam.jpg", name: "D", title: "Backend Technology Head" },
  
  ];

  return (
    <Container fluid className="about-us-section"> {/* Make container fluid to cover the full width */}
      <Row className="my-5 justify-content-center">
        <Col xs={12} className="text-center"> {/* Change to xs={12} to cover the full width */}
          <h2>About AI Diamonds</h2>
          <p>Welcome to AI Diamonds, the epitome of precision and brilliance in the diamond export industry. Our mission is to deliver an unmatched selection of premium diamonds that capture the essence of luxury and elegance.</p>
          <p>Our dedicated team, consisting of expert gemologists and visionary leaders, is committed to sourcing and crafting diamonds that meet the highest standards of quality and ethics. We believe in transparency, innovation, and the timeless beauty of diamonds.</p>
          <p>Established with a passion for excellence, AI Diamonds has been at the forefront of introducing advanced technology in diamond processing to ensure every gem we deliver is a masterpiece of cutting and clarity. As an esteemed ex-partner of Arjiv Export, we carry forward a legacy of exceptional service and expertise in the diamond trade.</p>
        </Col>
      </Row>
      <Row className="my-5 text-center">
        <Col xs={12}>
          <h2>Meet Our Team</h2>
        </Col>
      </Row>
      <Row className="text-center">
        {teamMembers.map((member, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Image src={member.src} alt={member.name} roundedCircle className="team-photo" />
            <h5 className="mt-2">{member.name}</h5>
            <p className="team-title">{member.title}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AboutUs;


