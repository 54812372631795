import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS

function Navbar() {
  const navLinkStyle = { fontSize: '1.1rem' };
  return (
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#414143' }}>
      <div className="container-fluid">
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link " style={navLinkStyle} aria-current="page" href="/">HOME</a>
            </li>
            <li className="nav-item">
            <a className="nav-link" style={navLinkStyle} href="/events">EVENTS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={navLinkStyle} href="/the-journey">THE JOURNEY</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle"style={navLinkStyle} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                COMPLIANCES
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a className="dropdown-item" href="/compliance-1">POLICIES-SUPPLY-CHAIN</a></li>
                <li><a className="dropdown-item" href="/compliance-2">RJC POLICY</a></li>
                <li><a className="dropdown-item" href="/compliance-3">PROVENANCE CLAIM</a></li>
                {/* Add more dropdown items here if needed */}
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={navLinkStyle} href="/Customization">CUSTOMIZATION</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={navLinkStyle} href="/whatwedo">What We Do</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link"style={navLinkStyle} href="/Aboutus">About Us</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link"style={navLinkStyle} href="/contactus">Contact Us</a>
            </li>
           
          
            {/* Add more navigation items here */}
          </ul>
          <form className="d-flex">
            {/* <button className="btn btn-outline-dark" type="submit">Login</button> */}
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
