import React from 'react';
import './Events.css'; // Ensure you have your custom styles

function Events() {
  return (
    <div className="events-container">
   <h2 className="text-center my-4 custom-heading">EVENTS UPDATES</h2>
<p className="text-center mb-4 custom-paragraph">WE EXHIBIT IN THE FOLLOWING SHOWS</p>

      <div className="container">
        <div className="row">
          {/* Large event card */}
          <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
            <div className="event-card">
              <img src="/events-1.jpg" alt="GemGeneve" className="event-image"/>
              <div className="event-caption bg-white"style={{ backgroundColor: '#e0dcd9', color: '#414143' }}>
              <p className="event-name" >GemGeneve</p>

              </div>
            </div>
          </div>
          {/* First small event card */}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="event-card mb-3">  {/* Margin bottom added here */}
              <img src="/events-2.jpg" alt="Event 2" className="event-image"/>
              <div className="event-caption bg-white">
                <p className="event-name">Jewellery Arabia</p>
              </div>
            </div>
            {/* Fourth image (below the first small card) */}
            <div className="event-card mt-3">  {/* Margin top added here */}
              <img src="/events-4.jpg" alt="Event 4" className="event-image"/>
              <div className="event-caption bg-white">
                <p className="event-name">Vicenzaoro</p>
              </div>
            </div>
          </div>
          {/* Second small event card */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div className="event-card">
              <img src="/events-3.jpg" alt="Event 3" className="event-image"/>
              <div className="event-caption bg-white">
                <p className="event-name">Jewellery & Gem World Singapore</p>
              </div>
            </div>
            {/* Add a little space between Event 3 and Event 5 */}
            <div className="mt-3"></div>
            {/* Fifth image (to the right of the second small card) */}
            <div className="event-card">
              <img src="/events-5.jpg" alt="Event 5" className="event-image"/>
              <div className="event-caption bg-white">
                <p className="event-name">Miami Beach Antique Show</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
