import React from 'react';
import './Journey.css'; // Ensure your custom styles are here

function Journey() {
  return (
    <div className="journey-container">
      <div className="background">
        <div className="centered-text">
          <h1>THE JOURNEY</h1>
          <p>WELCOME TO THE WORLD OF AI DIAMONDS.</p>
          <p> AI DIAMONDS EXPORTS has always believed in Innovation, Excellence, and Proficiency. Since then, we have been striving to meet the highest standards of manufacturing each diamond.</p>
        </div>
        <section style={{ backgroundColor: "#F0F2F5" }}>
  <div className="container py-5">
    <div className="main-timeline">
      <div className="timeline left">
        <div className="card">
          <div className="card-body p-4" >
            <h3>1980's - 1990's</h3>
            <h2>SINGLE CUT DIAMONDS</h2>
            <p className="mb-0 ">
           
        
         1980's - 1990's
SINGLE CUT DIAMONDS
A single cut on the other hand is a simple form of cutting a round diamond with only 17 or 18 facets. If you compare a Single Cut Diamond (usually small Carat Weights of 1.2mm or less), to a Full Cut Diamond (58 facets), you’d quickly see the difference. Small high quality single cut diamonds are highly prized especially in the watch industry, and because there are fewer but larger facets, they give off a majestic dance of light and colour, that is often lost in a sea of white light with the full cut stones
            </p>
          </div>
        </div>
      </div>
      <div className="timeline right">
        <div className="card">
          <div className="card-body p-4">
          <h3>2000's</h3>
          <h2>BRILLIANT CUT DIAMONDS</h2>
          <p className="mb-0 ">
          A brilliant cut is simple a diamond or other gemstone cut in a particular form with numerous facets so as to have exceptional brilliance. The shape resembles of a cone and provides maximised light return through the top of a diamond. Round Brilliant Cuts are moreover used in modern jewellery.

A brilliant cut is simply a diamond or any other gemstone cut in a particular form with numerous facets so to ensure exceptional brilliance.
           
        
         

 
              </p>
          </div>
        </div>
      </div>
      <div className="timeline left">
        <div className="card">
          <div className="card-body p-4">
            <h3>2008</h3>
            <h2>ROSE CUT DIAMONDS</h2>
            <p className="mb-0">
            2008
ROSE CUT DIAMONDS
The rose cut is one of the oldest cutting style for diamonds mordern jewellery designers are creating edges(Contemparory jewellery with an interesting, old world vibe by adding rose cut. The ancient art of faceting a stone to look like an opening nose, originated in India in the 16th century. The result is a stone that is still coveted today by high-end designers and consumers alike. This brilliant time-honored cut allows light to reflect within 24 domed facets(Size 0.01 to 20.00 cts), Colour(o-z.natural fancy colour).
            </p>
          </div>
        </div>
      </div>
      <div className="timeline right">
        <div className="card">
          <div className="card-body p-4">
            <h3>2012</h3>
            <h2>FANCY COLOURED DIAMONDS</h2>
            <p className="mb-0">
            Natural, Intense fancy coloured diamonds are as rare as they exquisite.AI DIAMONDS exports carries a variety of fancy colours in a range of shapes, sizes, colours and clarity. They are 100% natural and un-treated with heat or chemicals so not to compromise their value or quality.


            </p>
          </div>
        </div>
      </div>
      <div className="timeline left">
        <div className="card">
          <div className="card-body p-4">
            <h3>2015</h3>
            <h2>STEP CUT DIAMONDS</h2>
            <p className="mb-0">
            Stones whose outlines are either square or rectangular and whose facets are rectilinear and arranged parallel to the griddle known as step-or-step cut stones. These stones often have their corners truncated creating an embrald cut with octagonal outline.
            </p>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</section>

        </div>
      </div>
   
  );
}

export default Journey;
