import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import './Home.css'; // Import your custom CSS for Home

function Home() {
    return (
      <>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/Carousal-image1.jpeg" className="d-block w-100" alt="First Carousel"/>
            </div>
            <div className="carousel-item">
  <img src="/Carousal-image3.jpg" className="d-block w-100" alt="Second Carousel"/>
</div>
<div className="carousel-caption">
          <h2>EXCELLENCE AND PROFICIENCY</h2>
        </div>

          </div>
        </div>
        {/* <div className="carousel-caption">
          <h2>EXCELLENCE AND PROFICIENCY</h2>
        </div> */}
        {/* Add your image here */}
      
        {/* <div className="image-below-caption">
          <img src="/Home-2.jpg" className="d-block w-100" alt="Descriptive Alt Text"/>
        </div> */}
        <div className="image-and-text-container">
                <div className="image-container">
                    <img src="/Home-3.jpg" alt="Descriptive Alt Text"/>
                </div>
                <div className="text-container">
                    <div className="text-content">
                        <h2>Brilliant Cut</h2>
                        <p>A brilliant cut is simply a diamond or any other gemstone cut in a particular form with numerous facets so to ensure exceptional brilliance.</p>
                        {/* <button>Discover</button> */}
                    </div>
                </div>
            </div>

            <div className="container-step-cut-and-image">
  
            <div className="text-and-image-container step-cut-container">
            <div className="text-containerr">
    <div className="text-content">
      <h2>Step Cut</h2>
      <p>Stones whose outlines are either square or rectangular and whose facets are rectilinear and arranged parallel to the griddle known as step-or-step cut stones.</p>
      {/* <button>Discover</button> */}
    </div>
  </div>
  <div className="image-container">
    <img src="/Home-4.jpg" alt="Descriptive Alt Text for Home-4" className="move-left"/>
  </div>
  
</div>
</div>
<div className="container-step-cut-and-image">
  
<div className="text-and-image-container step-cut-container">
  <div className="image-container">
    <img src="/Home-5.jpg" alt="Descriptive Alt Text for Home-4" className="move-right" />
  </div>
  <div className="text-containerr specific-text-container">
    <div className="text-content">
      <h2>EUROPEAN CUT / ROSE CUT / PORTRAIT CUT</h2>
      <p>These are one of the oldest cutting style for diamonds. Modern jewellery designers are creating Contemperary jewellery with an interesting old world vibe or by adding rose cut.</p>
      {/* <button>Discover</button> */}
    </div>
  </div>
</div>

</div>
<div className="container-step-cut-and-image">
  
            <div className="text-and-image-container step-cut-container">
            <div className="text-containerr">
    <div className="text-content">
      <h2>EXQUISITE DIAMONDS</h2>
      <p>Royal Rays is the home to the world's most exquisite diamonds that continue to underline the legacy.

</p>
      {/* <button>Discover</button> */}
    </div>
  </div>
  <div className="image-container">
    <img src="/Home-6.jpg" alt="Descriptive Alt Text for Home-4" className="move-left"/>
  </div>
  
</div>
</div>
<div className="container-step-cut-and-image">
  
<div className="text-and-image-container step-cut-container">
  <div className="image-container">
    <img src="/Home-7.jpg" alt="Descriptive Alt Text for Home-4" className="move-right" />
  </div>
  <div className="text-containerr specific-text-container">
    <div className="text-content">
      <h2>FANCY COLOR</h2>
      <p>Natural Fancy colored diamonds are as rare as they exquisite. Royal Rays exports carries a variety of fancy colours in a range of shapes, sizes colours and clarity.</p>
      {/* <button>Discover</button> */}
    </div>
  </div>
</div>

</div>
<div className="grey-background-text-container">
<h3 className="the-heading">THE</h3>
<h1 className="journey-heading">Journey</h1>
  <h2 className="Welcome-heading">WELCOME TO THE WORLD OF Royal Rays.</h2>
  <p> Royal Rays EXPORTS has always believed in Innovation, Excellence and Proficiency.</p>
  <p> Since then,we have been striving to meet the highest standards of manufacturing each diamond.</p>
</div>

         
 </>
   );
}


export default Home;

