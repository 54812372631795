import React from 'react';
import './Header.css'; // Ensure you have this CSS file

const Header = () => {
  const logoPath = process.env.PUBLIC_URL + '/863.png';
  return (
    <div className="header-container">
       <img src={logoPath} alt="AI Diamonds Logo" className="header-logo" />
      {/* <img src="/AILOGO.jpeg" alt="AI Diamonds Logo" className="header-logo" /> Add the logo image here */}
      <div className="centered-content">
        <h1 className="header-title">AI Diamonds</h1>
      </div>
      <div className="header-partner-text">Ex Arjiv Exports Partner</div>
      
    </div>
  );
};

export default Header;



