import React from 'react';
import './Footer.css'; // Ensure you have your custom styles if needed
console.log("Google Maps link: https://maps.app.goo.gl/joebj1pixpfGS5pV9");

const Footer = () => {
  const showMessage = () => {
    alert('Add us on WeChat: +91 7045356688');
  };
  return (
    <footer className="footer-container bg-light text-dark pt-4">
      <div className="container">
        {/* Center Mumbai Office Section */}
        <div className="row justify-content-center">
          <div className="col-md-5 footer-address text-center">
            <h4><i className="fas fa-map-marker-alt"></i> Mumbai Office</h4>
            <p>DC-7260, Bharat Diamond Bourse, BKC, Bandra East, Mumbai, Maharashtra 400051</p>
            
            <a href="tel:+917045356688" className="fas fa-phone"> +91 7045356688</a>
            <p><i className="fas fa-envelope"></i>
    <a href="mailto:india@royalraysbv.com" className="footer-email-link"> india@royalraysbv.com</a>
  </p>
  
  <a href="https://maps.app.goo.gl/joebj1pixpfGS5pV9" target="_blank" rel="noopener noreferrer" className="map-link">
  <i className="fas fa-map-signs"></i> Google Map
</a>

          </div>
        </div>

        {/* Rest of your footer content */}
        <div className="row">
          <div className="col-12 text-center mt-3 mb-5">
            <h4>Social Media Links</h4>
            {/* Social Media Icons */}
            <div className="social-icons">
              {/* <i className="fab fa-facebook-f facebook-icon fa-2x mx-2"></i> */}
              
              <a href="https://www.instagram.com/royalraysbv" target="_blank" rel="noopener noreferrer" className="instagram-icon">
              <i className="fab fa-instagram fa-2x mx-2"></i>
            </a>
            <a href="https://www.youtube.com/@arjivexports9570" target="_blank" rel="noopener noreferrer" className="youtube-icon">
              <i className="fab fa-youtube fa-2x mx-2"></i>
            </a>
              <i className="fab fa-skype skype-icon fa-2x mx-2"></i>

              <a href="https://www.linkedin.com/company/royalray" target="_blank" rel="noopener noreferrer" className="youtube-icon">
              <i className="fab fa-linkedin-in linkedin-icon fa-2x mx-2"></i>
            </a>
           
            <a href="https://wa.me/917045356688" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
              <i className="fab fa-whatsapp fa-2x mx-2"></i>
            </a>
            <span onClick={showMessage} className="wechat-icon" style={{ cursor: 'pointer' }}>
              <i className="fab fa-weixin fa-2x mx-2"></i>
            </span>
            <a href="https://twitter.com/royalraysbv" target="_blank" rel="noopener noreferrer" className="twitter-icon">
              <i className="fab fa-twitter fa-2x mx-2"></i>
            </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
